import React from "react";
import { Container } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import AccordionItem from "./AccordionItem";

const FAQ = props => {
  return (
    <section className="faq-one">
      <img
        src="/images/shapes/faq-bg-1-1.png"
        className="faq-one__bg-shape-1"
        alt=""
      />
      <Container style={{ paddingTop: 140 }}>
        <BlockTitle
          textAlign="center"
          paraText="Learn More About the Project"
          titleText={`Frequently Asked Questions`}
        />
        <div className="accrodion-grp faq-accrodion">
          <AccordionItem
            title="What is Kibble Coin?"
            content=<div>
              <strong>Kibble is a cryptocurrency.</strong> It is build on the
              Binance Smart Chain and among other things helps raise money for
              animal shelters.
            </div>
            status={true}
          />
          <AccordionItem
            title="Do we hold AMAs?"
            content=<div>
              Yes! The team has scheduled bi-weekly AMAs as follows.
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div>1/10 2pm CT - Launch AMA</div>
                <div>1/24 2pm CT - Charity Voting AMA</div>
                <div>2/8 2pm CT - Utility AMA</div>
                <div>Further AMAs TBD</div>
              </div>
              AMAs held via Telegram voice
            </div>
            status={false}
          />
          <AccordionItem
            title="Listings"
            content={
              <div>
                <div style={{ marginBottom: 14 }}>
                  <strong>Current </strong> Pancakeswap
                </div>
                <div style={{ marginBottom: 14 }}>
                  <strong>Round 1 Goal (Q2 2022):</strong> Bitmart, Whitebit
                </div>
                <div>
                  <strong>Round 2 Goal (Q3/Q4 2022):</strong> KuCoin, gate.io,
                  Mandala
                </div>
              </div>
            }
            status={false}
          />

          <AccordionItem
            title="Available Charts?"
            content="Poocoin"
            status={false}
          />
        </div>
      </Container>
    </section>
  );
};

// <AccordionItem
//   title="Audits"
//   content="Dessert swap Pre Launch Audit planned for Q3 2021"
//   status={false}
// />

export default FAQ;
