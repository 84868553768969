import React, { useState } from "react";
import { useWindowWidth } from "../hooks/useWindowWidth";
import Modal from "react-bootstrap/modal";
import { FaReddit, FaDiscord } from "react-icons/fa";

export default function Banner() {
  const windowWidth = useWindowWidth();
  const [buttonHover, setButtonHover] = useState(false);
  const [presaleModal, setPresaleModal] = useState(false);
  const bgImage =
    windowWidth > 1100
      ? "/images/bgs/kibbleBG.png"
      : windowWidth > 600
      ? "/images/bgs/kibbleBGSM.png"
      : "/images/bgs/kibbleBGSM.png";
  const smallPadding = windowWidth < 756 ? "18px" : 0;
  const smallPaddingRight = windowWidth > 511 ? "" : 0;
  return (
    <section className="banner-one" id="home">
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-8"
            style={{ paddingLeft: 0, paddingRight: smallPaddingRight }}
          >
            <div
              className="banner-one__content"
              style={{ paddingLeft: smallPadding }}
            >
              <h3 style={{ color: "#fff", marginBottom: 20 }}>
                Buy Crypto,
                <br /> Feed Dogs
              </h3>
              <p style={{ color: "#fff" }}>
                Every time we hit one of our milestones more
                {windowWidth > 767 ? <br /> : " "}
                money goes to pet shelters, it's that simple
              </p>
              <div className="d-flex">
                <div
                  style={functionalStyles(buttonHover, "tele")}
                  className="d-flex justify-content-center align-items-center"
                  onMouseEnter={() => setButtonHover("tele")}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  <a href="https://t.me/shamancoin" target="blank">
                    <FaReddit style={styles.buttonIcon} className="mr-2" />
                    <span style={styles.button}>Reddit</span>
                  </a>
                </div>
                <div
                  style={functionalStyles(buttonHover, "presale")}
                  className="d-flex justify-content-center align-items-center ml-3"
                  onMouseEnter={() => setButtonHover("presale")}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  <a
                    href="https://discord.com/channels/923674851435237506/923674851435237508"
                    target="blank"
                  >
                    <FaDiscord style={styles.buttonIcon} className="mr-2" />
                    <span style={styles.button}>Discord</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={presaleModal} onHide={() => setPresaleModal(false)} centered>
        <div className="p-4">
          <div style={{ fontSize: 24 }} className="mb-3">
            Presale Coming Soon
          </div>
          We will begin the token presale and activiate this link when our
          Telegram group reaches 2000 members. For the most up to date info on
          the presale, join us on Telegram.
          <div className="d-flex justify-content-end mt-4">
            <div
              style={{
                width: 120,
                border: "2px solid black",
                height: 40,
                borderRadius: 4,
                cursor: "default"
              }}
              onClick={() => setPresaleModal(false)}
              className="d-flex justify-content-center align-items-center"
            >
              Close
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
}

const styles = {
  buttonIcon: {
    color: "#fff",
    fontSize: 22,
    marginBottom: 3
  },
  button: {
    color: "#fff",
    fontWeight: 600,
    cursor: "default",
    lineHeight: 1,
    fontSize: 18
  }
};

const functionalStyles = (buttonHover, value) => {
  return {
    width: 154,
    border: "2px solid #fff",
    height: 44,
    borderRadius: 4,
    opacity: buttonHover === value ? 0.75 : 1
  };
};
