import React from "react";
import BlockTitle from "../BlockTitle";
import { BiLinkExternal } from "react-icons/bi";
import { useWindowWidth } from "../../hooks/useWindowWidth";

export const walletLink = [
  {
    l: "Donation Treasury",
    a: "https://bscscan.com/address/0xE989E08E62DA084D59944CE5cE19F399B8486a5c"
  },
  {
    l: "Admin",
    a: "https://bscscan.com/address/0xE989E08E62DA084D59944CE5cE19F399B8486a5c"
  }
];

export const walletLink2 = [
  {
    l: "Marketing #1",
    a: "https://bscscan.com/address/0xE163654152A87FF4Bd61673Af31C66503dd01F44"
  },
  {
    l: "Marketing #2",
    a: "https://bscscan.com/address/0x57CB1C1d8E18628511FF95a0ADBcfF40D33A5A7B"
  }
];

const Services = () => {
  const windowWidth = useWindowWidth();
  return (
    <section
      className="service-one"
      id="tokenomics"
      style={{
        paddingTop: windowWidth > 400 ? "" : 100
      }}
    >
      <div
        className="container"
        style={{
          paddingTop: windowWidth > 1100 ? 100 : windowWidth > 600 ? 240 : 20
        }}
      >
        <div style={{ position: "relative" }}>
          <BlockTitle
            textAlign="center"
            titleText={`Tokenomics`}
            paraText2={"Initial Supply 1,000,000,000,000 Tokens"}
            // paraText3={"7% Transaction Tax"}
          />
          <div
            style={{ position: "absolute", left: "50%", top: -50 }}
            className="tokenBG"
          ></div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>

                <h3 style={{ marginBottom: 10 }}>Initial Sale</h3>
                <p>20% (20b tokens)</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>

                <h3 style={{ marginBottom: 10 }}>Donation Treasury</h3>
                <p>60% (60b tokens)</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>

                <h3 style={{ marginBottom: 10 }}>Market, Dev, Admin</h3>
                <p>20% (20b tokens)</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div className="col-lg-8 col-md-11">
            <div className="service-one__single mb-0">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <h3 className="mb-1">Transparency</h3>
                <p>Keep track of all of our wallets</p>
                <div className="d-flex justify-content-between pl-5 pr-5 mb-3 mt-3">
                  {walletLink.map(wl => (
                    <div className="p-2 wallet-button">
                      <a
                        href={wl.a}
                        className="wallet-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {wl.l}
                        <BiLinkExternal
                          className="ml-2"
                          style={{ marginBottom: 2 }}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
