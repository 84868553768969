import React from "react";
import BlockTitle from "./BlockTitle";
import { useWindowWidth } from "../hooks/useWindowWidth";

const CTAOne = () => {
  const windowWidth = useWindowWidth();
  const flexPosition =
    windowWidth > 991 ? "justify-content-end" : "justify-content-center";
  return (
    <section className="cta-one" id="about">
      <img
        src="/images/shapes/cta-1-shape-1.png"
        className="cta-one__bg-shape-1"
        alt=""
      />

      <div className="container">
        <div className={`row ${flexPosition}`}>
          <div
            className="cta-one__moc"
            style={{
              marginBottom: windowWidth > 991 ? 0 : 80,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img
              src="/images/chart.png"
              className="cta-one__moc-img"
              alt=""
              style={{
                border: "none",
                maxWidth: windowWidth > 900 ? "70%" : "100%"
              }}
            />
          </div>
          <div className="col-lg-6">
            <div className="cta-one__content">
              <BlockTitle
                textAlign="left"
                paraText="How it Works"
                titleText={`Every time we hit one of our milestones more money goes to pet shelters`}
              />

              <ul className="list-unstyled cta-one__list">
                <li>
                  <img
                    alt="icon"
                    src="/images/resources/icon.png"
                    style={{ width: 14, marginRight: 8 }}
                  />
                  1/2 a percent of the treasury is donated at each point
                </li>
                <li>
                  <img
                    alt="icon"
                    src="/images/resources/icon.png"
                    style={{ width: 14, marginRight: 8 }}
                  />
                  Community selected shelters and charities
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTAOne;
