import React from "react";
import { Link as ScrollLink } from "react-scroll";

const NavLinks = ({ page, sticky }) => {
  return (
    <ul className="main-nav__navigation-box">
      <li>
        <ScrollLink
          activeClass="current"
          to="tokenomics"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          style={{ color: sticky ? "rgb(117, 76, 36)" : "white" }}
        >
          Tokenomics
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="about"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          style={{ color: sticky ? "rgb(117, 76, 36)" : "white" }}
        >
          About
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="howTo"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          style={{ color: sticky ? "rgb(117, 76, 36)" : "white" }}
        >
          How to Buy
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="roadmap"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          style={{ color: sticky ? "rgb(117, 76, 36)" : "white" }}
        >
          Roadmap
        </ScrollLink>
      </li>
    </ul>
  );
};

export default NavLinks;
