import React, { useState, useEffect, useContext } from "react";
import NavLinks from "./NavLinks";
import { useWindowWidth } from "../hooks/useWindowWidth";
import { MenuContext } from "../context/MenuContext";
import { HiMenu } from "react-icons/hi";
import { FaReddit, FaDiscord } from "react-icons/fa";

const HeaderHome = props => {
  const [sticky, setSticky] = useState(false);
  const windowWidth = useWindowWidth();
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);
  const logoMargin = windowWidth > 580 ? "1rem" : 0;
  return (
    <header
      className={`site-header-one stricky  ${props.extraClassName} ${
        sticky === true ? "stricky-fixed stricked-menu" : " "
      }`}
    >
      <div className="container-fluid" style={{ minHeight: 64 }}>
        <div
          className="site-header-one__logo"
          style={{ paddingLeft: logoMargin }}
        >
          <a href="/">
            <img
              src={
                sticky ? "/images/logo/textBrown.png" : "/images/logo/text.png"
              }
              width="160"
              alt=""
            />
          </a>
          {props.page !== "whitePaper" && (
            <span className="side-menu__toggler" onClick={handleMenuClick}>
              <HiMenu style={{ fontSize: 24, color: "#fff" }} />
            </span>
          )}
        </div>
        {props.page !== "whitePaper" && (
          <div className="main-nav__main-navigation">
            <NavLinks page={props.page} sticky={sticky} />
          </div>
        )}
        {windowWidth > 535 && (
          <SocialLinks windowWidth={windowWidth} sticky={sticky} />
        )}
      </div>
    </header>
  );
};

const SocialLinks = ({ windowWidth, sticky }) => {
  return (
    <div
      style={{
        position: "absolute",
        right: 40,
        top: windowWidth > 1199 ? 13 : 7,
        fontSize: 30
      }}
    >
      <a
        href="https://www.reddit.com/r/kibble/"
        target="_blank"
        rel="noreferrer"
      >
        <FaReddit
          className="mr-3 icon-hover"
          style={{ color: sticky ? "rgb(117, 76, 36)" : "#fff" }}
        />
      </a>
      <a
        href="https://discord.com/channels/923674851435237506/923674851435237508"
        target="_blank"
        rel="noreferrer"
      >
        <FaDiscord
          className="icon-hover"
          style={{ color: sticky ? "rgb(117, 76, 36)" : "#fff" }}
        />
      </a>
    </div>
  );
};

export default HeaderHome;
