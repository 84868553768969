import React from "react";
import BlockTitle from "../BlockTitle";
import { BiLinkExternal } from "react-icons/bi";
import { useWindowWidth } from "../../hooks/useWindowWidth";

export const walletLink = [
  {
    l: "Treasury",
    a: "https://bscscan.com/address/0x7A12084A48E6928a8296074DcD5Bf46743e5bda5"
  },
  {
    l: "Admin",
    a: "https://bscscan.com/address/0xE989E08E62DA084D59944CE5cE19F399B8486a5c"
  }
];

export const walletLink2 = [
  {
    l: "Marketing #1",
    a: "https://bscscan.com/address/0xE163654152A87FF4Bd61673Af31C66503dd01F44"
  },
  {
    l: "Marketing #2",
    a: "https://bscscan.com/address/0x57CB1C1d8E18628511FF95a0ADBcfF40D33A5A7B"
  }
];

const Services = () => {
  const windowWidth = useWindowWidth();
  return (
    <section
      className="service-one"
      id="tokenomics"
      style={{
        paddingTop: windowWidth > 400 ? "" : 100
      }}
    >
      <div
        className="container"
        style={{
          paddingTop: windowWidth > 1100 ? 100 : windowWidth > 600 ? 240 : 20,
          marginBottom: 100
        }}
      >
        <BlockTitle
          textAlign="center"
          titleText={`Tokenomics`}
          paraText2={"Initial Supply 1,000,000,000,000 Tokens"}
        />

        <div
          className="col-lg-3 col-md-6"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="service-one__single" style={{ borderRadius: 10 }}>
            <div className="d-flex p-3">
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: windowWidth < 800 ? 10 : 0
                }}
              >
                <h3 style={{ marginBottom: 4 }}>Initial Offering</h3>
                <p style={{ marginLeft: 0, lineHeight: 1.4 }}>20% (20b)</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-3 col-md-6"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="service-one__single" style={{ borderRadius: 10 }}>
            <div className="d-flex p-3">
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: windowWidth < 800 ? 10 : 0
                }}
              >
                <h3 style={{ marginBottom: 4 }}>Donation Treasury</h3>
                <p style={{ marginLeft: 0, lineHeight: 1.4 }}>60% (60b)</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-3 col-md-6"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="service-one__single" style={{ borderRadius: 10 }}>
            <div className="d-flex p-3">
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: windowWidth < 800 ? 10 : 0
                }}
              >
                <h3 style={{ marginBottom: 4 }}>Marketing, Dev, Admin</h3>
                <p style={{ marginLeft: 0, lineHeight: 1.4 }}>20% (20b)</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-lg-3 col-md-6"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="service-one__single" style={{ borderRadius: 10 }}>
            <div
              className="justify-content-center d-flex align-items-center p-3"
              style={{ flexDirection: "column" }}
            >
              <h3 style={{ marginBottom: 4 }}>Transparency</h3>
              <p style={{ marginLeft: 0 }}>Keep track of all of our wallets</p>
              <div className="d-flex justify-content-between mt-2">
                {walletLink.map((wl, index) => (
                  <div
                    className="p-2 wallet-button"
                    style={{
                      marginRight: index !== 2 ? 10 : 0,
                      width: windowWidth < 800 ? 120 : ""
                    }}
                  >
                    <a
                      href={wl.a}
                      className="wallet-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {wl.l}
                      <BiLinkExternal
                        className="ml-2"
                        style={{ marginBottom: 2 }}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
