import React from "react";
import BlockTitle from "./BlockTitle";

import { useWindowWidth } from "../hooks/useWindowWidth";
// import CTAImage1 from "../assets/images/resources/cta-2-moc-1.png";
// import CTAImage2 from "../assets/images/resources/cta-2-moc-2.png";
// import CTAImage3 from "../assets/images/resources/cta-2-moc-3.png";
//
const CTATwo = () => {
  const windowWidth = useWindowWidth();
  return (
    <section
      className="cta-two"
      style={{
        position: "relative",
        paddingBottom: windowWidth < 993 ? 0 : ""
      }}
      id="howTo"
    >
      <img
        src="/images/shapes/faq-bg-1-1.png"
        className="mobile-app-bg-shape"
        alt=""
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-two__content">
              <BlockTitle
                textAlign="left"
                paraText="How To"
                titleText={`Buy Kibble Coin`}
              />

              <div className="cta-two__text">
                <ul
                  style={{
                    paddingLeft: 0,
                    paddingBottom: 30,
                    listStyleType: "none"
                  }}
                >
                  <li style={{ paddingBottom: 7 }}>
                    <img
                      alt="icon"
                      src="/images/resources/icon.png"
                      style={{ width: 14, marginRight: 8 }}
                    />
                    Visit{" "}
                    <a href="https://pancakeswap.finance/">
                      www.pancakeswap.finance
                    </a>{" "}
                    and click trade, then exchange
                  </li>
                  <li style={{ paddingBottom: 7 }}>
                    <img
                      alt="icon"
                      src="/images/resources/icon.png"
                      style={{ width: 14, marginRight: 8 }}
                    />
                    In the top right corner, connect your wallet
                  </li>
                  <li style={{ paddingBottom: 7 }}>
                    <img
                      alt="icon"
                      src="/images/resources/icon.png"
                      style={{ width: 14, marginRight: 8 }}
                    />
                    Choose BNB on top, then drop down the bottom and click
                    manage tokens
                  </li>
                  <li style={{ paddingBottom: 7 }}>
                    <img
                      alt="icon"
                      src="/images/resources/icon.png"
                      style={{ width: 14, marginRight: 8 }}
                    />
                    Switch from lists to tokens and paste our token address:
                    <strong> 0x426a58f8a9d764B71387cb2c470540eB9374E957</strong>
                  </li>
                  <li>
                    <img
                      alt="icon"
                      src="/images/resources/icon.png"
                      style={{ width: 14, marginRight: 8 }}
                    />
                    Choose an amount to purchase and click swap!
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div
              className="cta-two__images d-flex justify-content-end align-items-end flex-column"
              style={{ height: 616 }}
            >
              <img
                src="/images/pancake.png"
                className="sm-screen-move"
                data-wow-duration="1500ms"
                alt=""
                style={{
                  maxWidth: "400px",
                  position: windowWidth < 993 ? "absolute" : "relative",
                  top: -80,
                  zIndex: windowWidth < 993 ? -1 : ""
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// <div className="cta-two__icon-wrap">
//   <div className="cta-two__icon-single">
//     <div className="cta-two__icon">
//       <i className="apton-icon-app-development"></i>
//     </div>
//     <h3>
//       Responsive <br /> Design
//     </h3>
//   </div>
//   <div className="cta-two__icon-single">
//     <div className="cta-two__icon">
//       <i className="apton-icon-computer-graphic1"></i>
//     </div>
//     <h3>
//       Online <br /> Marketing
//     </h3>
//   </div>
// </div>

export default CTATwo;
